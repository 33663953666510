import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import Contact from "../components/Form/ContactPage";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Contact Us | Colorado Lawyers | JBP"
        description="Reach out to our team of Colorado attorneys if you're in need of legal advice. The legal team at Jorgensen, Brownell & Pepin, P.C. is ready to assist you!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-12 pb-20 md:pb-28">
        <div className="container">
          <header className="max-w-[715px] mb-12 md:mb-16">
            <h1>Contact Jorgensen, Brownell & Pepin, P.C. Today!</h1>
          </header>

          <div className="grid md:grid-cols-2 gap-y-12 md:gap-x-10 lg:gap-x-20">
            <div>
              <div className="heading-three">Our Locations</div>
              <div>
                <AniLink fade to="/broomfield/" className="group">
                  <div className="inline-flex flex-row space-x-6 items-center mb-10">
                    <div className="overflow-hidden">
                      <GatsbyImage
                        image={data.broomfield.childImageSharp.gatsbyImageData}
                        className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                      />
                    </div>

                    <div>
                      <div className="font-heading text-lg text-secondary-50 font-extrabold mb-1.5">
                        Broomfield Office
                      </div>
                      <address className="not-italic">
                        <a
                          href="https://goo.gl/maps/4xirs8pC3yyd7qNF7"
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary-50 hover:text-primary-600"
                        >
                          8001 Arista Pl. Suite 415
                          <br /> Broomfield, CO 80021
                        </a>
                      </address>
                      <a
                        href="tel:720-499-1842"
                        className="text-primary-50 hover:text-primary-600"
                      >
                        (720) 499-1842
                      </a>
                    </div>
                  </div>
                </AniLink>

                <AniLink fade to="/longmont/" className="group">
                  <div className="inline-flex flex-row space-x-6 items-center mb-10">
                    <div className="overflow-hidden">
                      <GatsbyImage
                        image={data.longmont.childImageSharp.gatsbyImageData}
                        className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                      />
                    </div>

                    <div>
                      <div className="font-heading text-lg text-secondary-50 font-extrabold mb-1.5">
                        Longmont Office
                      </div>
                      <address className="not-italic">
                        <a
                          href="https://goo.gl/maps/DxZwHWW6xLyDTrDD9"
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary-50 hover:text-primary-600"
                        >
                          900 S Main St. Suite 100
                          <br /> Longmont, CO 80501
                        </a>
                      </address>
                      <a
                        href="tel:720-491-3117"
                        className="text-primary-50 hover:text-primary-600"
                      >
                        (720) 491-3117
                      </a>
                    </div>
                  </div>
                </AniLink>

                <AniLink fade to="/loveland/" className="group">
                  <div className="flex flex-row space-x-6 items-center">
                    <div className="overflow-hidden">
                      <GatsbyImage
                        image={data.loveland.childImageSharp.gatsbyImageData}
                        className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                      />
                    </div>

                    <div>
                      <div className="font-heading text-lg text-secondary-50 font-extrabold mb-1.5">
                        Loveland Office
                      </div>
                      <address className="not-italic">
                        <a
                          href="https://goo.gl/maps/qsKJarM8tyr9KKMb9"
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary-50 hover:text-primary-600"
                        >
                          5285 McWhinney Blvd. Suite 100
                          <br /> Loveland, CO 80538
                        </a>
                      </address>
                      <a
                        href="tel:970-644-6275"
                        className="text-primary-50 hover:text-primary-600"
                      >
                        (970) 644-6275
                      </a>
                    </div>
                  </div>
                </AniLink>
              </div>
            </div>
            <div>
              <Contact />
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    hero: file(relativePath: { eq: "locations/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 588)
      }
    }
    broomfield: file(relativePath: { eq: "modal/broomfield.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    longmont: file(relativePath: { eq: "modal/longmont.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    loveland: file(relativePath: { eq: "modal/loveland.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    map: file(relativePath: { eq: "locations/2.0 Map.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
  }
`;

export default Page;
